<template>
  <v-dialog v-model="printOptionsDialog" max-width="500" @keydown.esc="cancel">
    <v-card max-width="500" elevation="4" width="500" outlined>
      <v-subheader class="body-1 blue--text">Print (experimental) <v-divider inset></v-divider>
      </v-subheader>
      <v-card-text>
        <v-row>
          <v-col cols="12"> <span>Printing <strong>{{data.ref}}</strong></span> </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="pdf_filename" hide-details color="blue-grey darken-1" class="mr-2" outlined maxlength="30" counter="30" clearable dense placeholder="Save PDF as..." suffix="PDF">
            </v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-btn small @click="exprint" dark block color="blue darken-2" :disabled="!pdf_filename">Print</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="grey lighten-4">
        <span class="caption">Press ESC to close window</span>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import axios from 'axios'
  export default {
    name: "PrintOptionsDialog",
    // components: { PrintDialog: () => import('./dlgPrintFormula'), },
    data() {
      return {
        pdf_filename: '',
        printOptionsDialog: false,
        print_columns: ['Item', 'Code', 'Value', 'Batch', 'Percent', 'RMC', 'CIU'],
        select_columns: ['Item', 'Code', 'Value', 'Batch', 'Percent'],
      }
    },
    props: ['data'],
    methods: {
      exprint() {
        axios.post(`${this.$store.getters.NODEURL}/applications/pdf/create/lab`, { data: this.data }, { responseType: 'arraybuffer' }, { withCredentials: true })
             .then((response) => {
                let filesaver = require('file-saver')
                filesaver.saveAs(new Blob([response.data], {type: "application/pdf"}), `${this.pdf_filename}.pdf`)
                this.pdf_filename = ''
              })
             .catch(() => {  })
             .finally(() => {
                this.pdf_filename = ''
                this.printOptionsDialog = false
             })
      },
      print () {
        // open(base_item, select_columns, formula_type, batch_size, formulation) {
        // this.$refs.printWindow.open(this.base, {}, '', '', this.items)
      },
      open() {
        this.printOptionsDialog = true
      },
      cancel() {
        this.printOptionsDialog = false
      }
    },
  }
</script>

<style scoped>
  >>> .vswitch .v-label { font-size: 14px!important }
</style>